// Here you can add other styles

.navbar-brand-full{
    font-weight: 800;
}
.table{
    thead{
        th{
            /* color: #677788;*/
   /* background-color: #f8fafd;
    border-color: rgba(231,234,243,.7);*/
                font-size: .75rem;
    text-transform: capitalize;
    font-weight: 600;
        }
    }
    
    td{
            vertical-align: middle;
    }
}

cui-breadcrumb{
    .breadcrumb{
        margin: 0px;
    }   
}

.navbar{
    .breadcrumb{
        border: 0px;
    }
}

.sidebar .sidebar-minimizer::before{
    filter: grayscale(1) brightness(2);
}
.sidebar .nav-dropdown-toggle::before{
    filter: grayscale(1) brightness(2); 
    transform: rotate(180deg);
}