// If you want to override variables do it here

@import "variables";

// Import styles with default layout.
// If you are going to use dark layout please comment next line
//"node_modules/bootstrap/dist/css/bootstrap.css",

@import "~@coreui/coreui-pro/scss/coreui";
.form-select{
    font-size: 0.85rem;  
}
.app-header {
    border: none;
}
.app-forgotpassword{
    min-height:100vh;
}
// Import styles with dark layout
// If you want to use dark layout uncomment next line
//@import "~@coreui/coreui-pro/scss/themes/dark/coreui-dark";

// If you want to add something do it here
@import "custom";

// Spinkit
//$spinkit-spinner-color: $body-color;
@import '~spinkit/scss/spinkit.scss';
@for $i from 10 through 50 {
    .fs-#{$i} {
        font-size:#{$i}px !important;
    }

}

@for $i from 1 through 9 {
    .fw-#{($i * 100)} {
        font-weight: ($i * 100) !important;
    }
}

.bottom-action{
    @media (min-width: 768px) {
        margin-left: 200px;
        
    }
}
/*
.form-select{
    font-size: 0.85rem;
}*/
.table th{
    border-top: 0px;
}
